<div class="kt-portlet">
    <div class="kt-portlet__head kt-portlet__head__custom">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">{{data.title}}</h3>
        </div>
    </div>
    <div class="kt-form">
        <div class="kt-portlet__body" style="max-height: 40vh; max-width: 60vw;">
            <div class="container">
                <div fxLayout="column" fxLayoutGap="0.5vw">
                    <input [hidden]="true" type="file" name="image" #fileselect accept="image/*"
                        (change)="fileChangeEvent($event)" />

                    <image-cropper style="height: 300px;" [imageURL]="imageUrlPath"
                        [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="1"
                        format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
                        (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"></image-cropper>

                    <!-- <img [src]="croppedImage" /> -->
                </div>
            </div>
        </div>
        <div class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit text-right">
            <div class="kt-form__actions kt-form__actions--solid">
                <br />
                <button mat-raised-button (click)="onNoClick()">{{'Cancel' |
                    translate}}</button>&nbsp;
                <button mat-raised-button (click)="fileselect.click()" color="primary">{{'Browse Files' |
                    translate}}</button>&nbsp;
                <button mat-raised-button color="primary" (click)="onYesClick(croppedImage)">{{'Save' |
                    translate}}</button>
            </div>
        </div>
    </div>
</div>