import { Component, OnDestroy, OnInit } from '@angular/core';
import { RequestService } from './core/services/request.service';

@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet>`
})
export class AppComponent implements OnInit, OnDestroy {

  private subscriptions: any[] = <any>[];
  private dataSeenOnce: boolean = false;

  constructor(private requestService: RequestService) {
    this.getMe();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.requestService.authenticatedUser.subscribe((event: boolean) => {
        if (event) {
          let rememberMe = false;
          if (localStorage.getItem('rememberMe')) {
            rememberMe = JSON.parse(localStorage.getItem('rememberMe') || '');
          }
          // if (!rememberMe) {
          //   this.resetActivity();
          // } else {
          //   // this.idle.stop();
          //   // console.log('Idle Activity Cancelled');
          // }
          // console.log('authenticatedUser' ,event);
          // console.log('this.dataSeenOnce' ,this.dataSeenOnce);
          if (!this.dataSeenOnce) {
            this.getMe();
          }
        } else {
          // this.idle.stop();
        }
      }
      ));

    this.subscriptions.push(
      this.requestService.appStatusSubject.subscribe((data: any) => {
        if (data) {
          if (data === 'login') {
            if (sessionStorage.getItem('live')) {
              // this.userActivityService.insertLog('logout', 'appStatusSubject', 'live sessionStorage', data);
              setTimeout(() => {
                this.requestService.logout(true, true);
              }, 200);
              // setTimeout(() => {
              //   this.alreadyLoggedIn('Logged In Elsewhere', 'You got logged out because you logged in from another location', 'Back to log in page');
              // }, 300);
            } else {
              // this.userActivityService.insertLog('logout', 'appStatusSubject', 'No live sessionStorage', data);
              setTimeout(() => {
                this.requestService.logout();
              }, 200);
            }
          } else if (data === 'default') {
            // this.userActivityService.insertLog('logout', 'appStatusSubject', 'Default sessionStorage', data);
            setTimeout(() => {
              this.requestService.logout();
            }, 200);
          }
        }
      }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  public getMe() {
    if (!localStorage.getItem('uid')) {
      localStorage.setItem('uid', JSON.stringify(Date.now()));
    }
    if (localStorage.getItem('currentUser') && localStorage.getItem('o') && localStorage.getItem('a') && localStorage.getItem('l')) {
      let currentUser = JSON.parse(localStorage.getItem('currentUser') || '');
      let orgId = JSON.parse(localStorage.getItem('o') || '');
      let appId = JSON.parse(localStorage.getItem('a') || '');
      let locId = JSON.parse(localStorage.getItem('l') || '');
      let orgData = JSON.parse(localStorage.getItem('org') || '');
      this.requestService.orgId = orgId;
      this.requestService.orgId = orgId;
      this.requestService.orgName = orgData.name;
      this.requestService.locId = locId;
      // if (orgData.hasOwnProperty('name')) {
      //   this.title.setTitle('Stellar - ' + orgData.name);
      // }
      this.requestService.pageOrganization.next(orgData);
      this.refreshOrg(orgId);
      this.requestService.currentUser = currentUser;
      let resource = this.requestService.getItemFromListContains(currentUser.resources, orgId, 'organizationId');
      if (resource) {
        this.requestService.updatePermissions(resource);
        this.dataSeenOnce = true;
        this.validateMeByApi();
      }
    } else {
      this.dataSeenOnce = false;
    }
  }

  public validateMeByApi() {
    this.requestService.getMe((data, error) => {
      if (error) {
        this.requestService.logout();
        //console.log(error);
      }
      if (data) {
        sessionStorage.setItem('live', JSON.stringify(true));
        this.requestService.authenticatedUser.next(true);
        if (localStorage.getItem('v')) {
          let versionData = JSON.parse(localStorage.getItem('v') || '');
          if (this.requestService.version !== versionData) {
            this.requestService.newAppVersion.next(true);
          }
        }
        // valid
      } else {
        this.dataSeenOnce = false;
        // this.requestService.logout();
      }
    });
  }

  public refreshOrg(orgId: any) {
    this.requestService.getSingleData('organization', this.requestService.orgId, (data: any, error: any) => {
      if (error) {
        // do nothing
      }
      if (data && data.results) {
        let orgData = data.results;
        // if (orgData.hasOwnProperty('name')) {
        //   this.title.setTitle('Stellar - ' + orgData.name);
        // }
        localStorage.setItem('org', JSON.stringify(orgData));
        this.requestService.pageOrganization.next(orgData);
        // this.featuresPermissionService.setUpOrg(orgData);
      }
    });
  }
}
