import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { AuthenticationService } from "src/app/core/services/auth.service";
import { LayoutUtilsService } from "src/app/core/services/layout-utils.service";
import { RequestService } from "src/app/core/services/request.service";
import { environment } from "src/environments/environment";

@Component({
    selector: 'app-clients',
    templateUrl: './clients.component.html',
    styleUrls: ['./clients.component.scss']
})

export class ClientsComponent implements OnInit, OnDestroy {

    private currentUser: any;
    clientId: string = '';
    public defaultPictureLink: string = 'assets/images/profile.png';
    public pictureLink: string = JSON.parse(JSON.stringify(this.defaultPictureLink));
    public originalData: any = undefined;
    loading: boolean = false;
    imageChanged: any = undefined;

    firstNameFormControl = new FormControl('', Validators.required);
    lastNameFormControl = new FormControl('', Validators.required);
    emailFormControl = new FormControl('', [Validators.required, Validators.email]);

    private subscriptions: any[] = <any>[];
    private type; string = 'user';

    constructor(private authService: AuthenticationService, private requestService: RequestService, private layoutUtilsService: LayoutUtilsService, private translate: TranslateService, private dialogRef: MatDialogRef<ClientsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        if (data.id) {
            this.clientId = data.id;
            this.emailFormControl.setValue(data.email);
            this.firstNameFormControl.setValue(data.firstName);
            this.lastNameFormControl.setValue(data.lastName);
            this.pictureLink = data.pictureLink;
        }
        this.type = data.type;
    }

    ngOnInit() {
        this.currentUser = this.authService.getCurrentUser();
    }

    ngOnDestroy() {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    private validate(): boolean {
        this.firstNameFormControl.markAsTouched();
        this.lastNameFormControl.markAsTouched();
        this.emailFormControl.markAsTouched();
        return this.firstNameFormControl.hasError('required') || this.lastNameFormControl.hasError('required') || this.emailFormControl.hasError('required');
    }

    saveClient() {
        if (!this.validate()) {
            this.loading = true;
            this.requestService.saveRecord({
                firstName: this.firstNameFormControl.value,
                lastName: this.lastNameFormControl.value,
                organizationId: [{
                    _id: this.requestService.orgId
                }],
                email: this.emailFormControl.value,
                resources: [{
                    _id: this.type === 'admin' ? environment.customKeys.roleAdmin : environment.customKeys.roleView
                }]
            }, 'user', (data: any, error: any) => {
                // console.log(data, error)

                if (data) {
                    this.dialogRef.close({ redirectTo: '/clients/list' });
                    this.layoutUtilsService.showNotification(this.translate.instant('Saved Successfully'), this.translate.instant('Dismiss'));
                }
                if (error)
                    this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
                this.loading = false;
            });
        }
    }

    updateClient() {
        if (!this.validate()) {
            this.loading = true;
            this.requestService.updateRecord({
                firstName: this.firstNameFormControl.value,
                lastName: this.lastNameFormControl.value,
                email: this.emailFormControl.value
            }, 'user/profileupdate', this.clientId + '/' + this.requestService.orgId, (data: any, error: any) => {
                // console.log(data, error)

                if (data) {
                    this.dialogRef.close({ redirectTo: '/clients/list' });
                    this.layoutUtilsService.showNotification(this.translate.instant('Updated Successfully'), this.translate.instant('Dismiss'));
                }
                if (error)
                    this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
                this.loading = false;
            });
        }
    }

    closeDialog(data?: any) {
        this.dialogRef.close(data);
    }
}