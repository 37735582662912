<div fxLayout="row" fxLayoutAlign="center none">
    <div fxFlex="95%" style="height: 500px;">
        <div class="container" fxLayout="column">
            <div fxFlex fxGrow="0" class="title">
                {{flowName}}
            </div>
            <div fxLayout="row" fxLayoutGap="1vw" fxLayoutAlign="end end" class="tools" *ngIf="toJsonTemplate">
                <mat-spinner [diameter]="20" *ngIf="loading" style="margin-right: 20px;"></mat-spinner>
                <mat-icon (click)="convertToJsonTemplate(true)" [ngStyle]="{'color': diagramChanged ? 'orange' : ''}"
                    [title]="'Save' | translate">save</mat-icon>
                <mat-icon [matMenuTriggerFor]="menuImport" [title]="'Import BPMN' | translate">file_download
                </mat-icon>
                <mat-menu #menuImport="matMenu">
                    <button mat-menu-item (click)="importFile()">{{'Import BPMN File' | translate }}</button>
                    <button mat-menu-item (click)="importExcel()">{{'Import CSV File' | translate }}</button>
                    <button mat-menu-item (click)="importDiagram()">{{'Import BPMN URL' | translate }}</button>
                </mat-menu>
                <mat-icon [matMenuTriggerFor]="menuDownload" [title]="'Export' | translate">file_upload</mat-icon>
                <mat-menu #menuDownload="matMenu">
                    <button mat-menu-item (click)="saveSVG()">{{'Download as SVG' | translate }}</button>
                    <button mat-menu-item (click)="saveFile()">{{'Download as BPMN' | translate }}</button>
                </mat-menu>
                <div style="position: relative; height: 24px;">
                    <mat-icon (click)="validate()" (mouseover)="showErrorDialog()" (mouseout)="hideErrorDialog()"
                        [ngStyle]="{ 'color': validatingStatus === 2 ? 'green' : validatingStatus === 1 ? 'orange' : validatingStatus === -1 ? 'red': ''}"
                        [title]="'Validate Diagram' | translate">verified</mat-icon>
                    <div class="diagram-errors" *ngIf="validationErrors?.length && showErrors"
                        (mouseover)="showErrorDialog()" (mouseout)="hideErrorDialog()">
                        <span *ngFor="let error of validationErrors">{{error}}<br></span>
                    </div>
                </div>
            </div>
            <div fxFlex class="text-center no-records">
                <div id="bpmn-tool" style="width: 100%; height: 80vh"></div>
                <!-- <iframe id="bpmn-tool" src="/assets/bpmn/index.html" style="width: 100%; height: 100%;"></iframe> -->
            </div>
        </div>
    </div>
</div>
<div class="controls">
    <div fxLayout="column" fxLayoutGap="0" style="height: 100%;">
        <mat-icon (click)="zoomIn()" fxFlex [title]="'Zoom In' | translate">zoom_in</mat-icon>
        <mat-icon (click)="zoomOut()" fxFlex [title]="'Zoom Out' | translate">zoom_out</mat-icon>
        <mat-icon (click)="resetZoom()" fxFlex [title]="'Reset Zoom' | translate">center_focus_weak</mat-icon>
    </div>
</div>
<div class="side-panel animate" *ngIf="currentNode?.id">
    <div class="container" fxLayout="column" fxLayoutGap="0.5vw" fxAlign="center center">
        <div fxLayout="row" fxFlex>
            <div fxFlex class="state-title">
                <span *ngIf="currentNode.stateTitle">
                    {{currentNode.type}}: {{currentNode.stateTitle}}
                </span>
            </div>
            <div fxFlex fxLayoutAlign="end start" class="close" fxGrow="0">
                <button mat-mini-fab color="primary" fxGrow="0" style="width: 25px; height: 25px;"
                    (click)="closeSidePanel()" [title]="'Close' | translate">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>
        <div class="content">
            <mat-card>
                <mat-card-content>
                    <div fxLayout="column" fxLayoutGap="0.5vw">
                        <mat-form-field>
                            <span class="insert-character">
                                <mat-icon (click)="insertSpecialString(maleContent, maleContentFormControl)"
                                    [title]="'Click here to insert a special string that will be replaced by the character name' | translate">
                                    perm_identity</mat-icon>
                            </span>
                            <mat-label>{{'Male Content' | translate}}</mat-label>
                            <textarea matInput placeholder="" rows="2" #maleContent
                                [formControl]="maleContentFormControl"></textarea>
                        </mat-form-field>
                        <mat-form-field>
                            <span class="insert-character">
                                <mat-icon (click)="insertSpecialString(femaleContent, femaleContentFormControl)"
                                    [title]="'Click here to insert a special string that will be replaced by the character name' | translate">
                                    perm_identity</mat-icon>
                            </span>
                            <mat-label>{{'Female Content' | translate}}</mat-label>
                            <textarea matInput placeholder="" rows="2" #femaleContent
                                [formControl]="femaleContentFormControl"></textarea>
                        </mat-form-field>
                        <mat-form-field>
                            <span class="insert-character">
                                <mat-icon (click)="insertSpecialString(otherContent, otherContentFormControl)"
                                    [title]="'Click here to insert a special string that will be replaced by the character name' | translate">
                                    perm_identity</mat-icon>
                            </span>
                            <mat-label>{{'Other Content' | translate}}</mat-label>
                            <textarea matInput placeholder="" rows="2" #otherContent
                                [formControl]="otherContentFormControl"></textarea>
                        </mat-form-field>
                        <mat-form-field *ngIf="currentNode.isCondition">
                            <mat-label>{{'Score' | translate}}</mat-label>
                            <input matInput placeholder="" min="0" type="number" [formControl]="scoreFormControl">
                        </mat-form-field>
                        <ng-container *ngIf="!currentNode.isCondition">
                            <mat-form-field style="width: 100%;">
                                <mat-label>{{'Background' | translate}}</mat-label>
                                <button mat-icon-button matPrefix (click)="backgroundFileInput.click()">
                                    <mat-icon>attach_file</mat-icon>
                                </button>
                                <mat-icon class="button-suffix-close" matSuffix (click)="removeFile('background')">close
                                </mat-icon>
                                <input type="text" readonly matInput [formControl]="backgroundFileDisplay" />
                                <input type="file" hidden #backgroundFileInput [accept]="acceptedImageExtensions"
                                    (change)="handleFileInputChange(backgroundFileInput.files, 'background')" />
                                <input type="hidden" value="" [formControl]="backgroundFileId" />
                                <mat-hint>{{'Allowed extensions jpeg, jpg, png' | translate}}</mat-hint>
                            </mat-form-field>
                            <mat-form-field style="width: 100%;">
                                <mat-label>{{'Audio File' | translate}}</mat-label>
                                <button mat-icon-button matPrefix (click)=" audioFileInput.click()">
                                    <mat-icon>attach_file</mat-icon>
                                </button>
                                <mat-icon class="button-suffix-close" matSuffix (click)="removeFile('audio')">close
                                </mat-icon>
                                <input type="text" readonly matInput [formControl]="audioFileDisplay" />
                                <input type="file" hidden #audioFileInput [accept]="acceptedAudioExtensions"
                                    (change)="handleFileInputChange(audioFileInput.files, 'audio')" />
                                <input type="hidden" value="" [formControl]="audioFileId" />
                                <mat-hint>{{'Allowed extensions wav, mp3' | translate}}</mat-hint>
                            </mat-form-field>
                            <mat-form-field style="width: 100%;">
                                <mat-label>{{'Video File' | translate}}</mat-label>
                                <button mat-icon-button matPrefix (click)="videoFileInput.click()">
                                    <mat-icon>attach_file</mat-icon>
                                </button>
                                <mat-icon class="button-suffix-close" matSuffix (click)="removeFile('video')">close
                                </mat-icon>
                                <input type="text" readonly matInput [formControl]="videoFileDisplay" />
                                <input type="file" hidden #videoFileInput [accept]="acceptedVideoExtensions"
                                    (change)="handleFileInputChange(videoFileInput.files, 'video')" />
                                <input type="hidden" value="" [formControl]="videoFileId" />
                                <mat-hint>{{'Allowed extensions mp4, mpeg' | translate}}</mat-hint>
                            </mat-form-field>
                        </ng-container>
                        <mat-form-field appearance="fill" *ngIf="currentNode.isCondition">
                            <mat-label>{{tagsTitle}}</mat-label>
                            <mat-select [formControl]="tagformControl" multiple>
                                <mat-option *ngFor="let tag of tags" [value]="tag.name">{{tag.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="fill"
                            *ngIf="currentNode.isCondition && flowRecord.allowDecisionFeedback">
                            <mat-label>{{'Feedback' | translate}}</mat-label>
                            <textarea matInput [formControl]="feedbackControl"></textarea>
                        </mat-form-field>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>