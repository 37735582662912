<div class="container" fxLayout="row" fxLayoutAlign="center none">
    <div fxFlex="95%">
        <div class="container" fxLayout="column">
            <div fxLayout="row" fxLayoutGap="0.5vw" fxLayoutAlign="end end">
                <button mat-raised-button color="primary" (click)="assignFlows()" [title]="'Assign Flows' | translate"
                    *ngIf="type !== 'admin'">
                    {{'Assign Flows' |
                    translate}}</button>
                <button mat-raised-button color="primary" (click)="addClient()"
                    [title]="'Create ' + displayType | translate">
                    {{'Create ' + displayType |
                    translate}}</button>
                <button mat-raised-button color="primary" (click)="importUsers()"
                    [title]="'Import ' + displayType | translate">
                    {{'Import ' + displayType + 's' |
                    translate}}</button>
            </div>
            <div fxFlex class="no-records animate">
                <table mat-table [dataSource]="datasource" class="mat-elevation-z8 table" *ngIf="datasource">
                    <ng-container matColumnDef="pictureLink">
                        <th mat-header-cell *matHeaderCellDef style="width: 60px;"></th>
                        <td mat-cell *matCellDef="let element">
                            <img class="circle" [src]="element.pictureLink || defaultPictureLink"
                                style="width: 30px; height: 30px;">
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef> {{'Name' | translate}} </th>
                        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                    </ng-container>
                    <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef style="width: 10%;"> {{'Email' | translate}} </th>
                        <td mat-cell *matCellDef="let element"> {{element.email}} </td>
                    </ng-container>
                    <ng-container matColumnDef="tools">
                        <th mat-header-cell *matHeaderCellDef style="width: 20%;"></th>
                        <td mat-cell *matCellDef="let element" class="tools">
                            <mat-icon (click)="editClient(element)" [title]="'Edit ' + displayType | translate">
                                edit</mat-icon>
                            <mat-icon (click)="deleteClient(element._id)" [title]="'Delete ' + displayType | translate">
                                delete
                            </mat-icon>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>

                <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
                </mat-paginator>
            </div>
        </div>
    </div>
</div>