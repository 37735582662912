<div class="kt-portlet">
    <div class="kt-portlet__head kt-portlet__head__custom">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">{{data.title}}</h3>
            <span class="kt-portlet__head-icon kt-hide">
                <i class="la la-gear"></i>
            </span>
        </div>
    </div>
    <div class="kt-form">
        <div class="kt-portlet__body">
            <mat-spinner [diameter]="50" class="loader" *ngIf="loading"></mat-spinner>
            <div class="container" *ngIf="!loading">
                <div fxLayout="column" fxLayoutGap="0.5vw">
                    <mat-form-field appearance="fill" fxFlex>
                        <mat-label>{{'Name' | translate}}</mat-label>
                        <input matInput placeholder="" required [formControl]="nameFormControl">
                        <mat-error *ngIf="nameFormControl.hasError('required')">
                            {{'Name is required' | translate}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>{{'Male Content' | translate}}</mat-label>
                        <span class="insert-character">
                            <mat-icon (click)="insertSpecialString(maleContent, maleContentFormControl)"
                                [title]="'Click here to insert a special string that will be replaced by the character name' | translate">
                                perm_identity</mat-icon>
                        </span>
                        <textarea matInput placeholder="" rows="5" [formControl]="maleContentFormControl"
                            #maleContent></textarea>
                        <mat-error *ngIf="maleContentFormControl.hasError('required')">
                            {{'Male content is required' | translate}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>{{'Female Content' | translate}}</mat-label>
                        <span class="insert-character">
                            <mat-icon (click)="insertSpecialString(femaleContent, femaleContentFormControl)"
                                [title]="'Click here to insert a special string that will be replaced by the character name' | translate">
                                perm_identity</mat-icon>
                        </span>
                        <textarea matInput placeholder="" rows="5" [formControl]="femaleContentFormControl"
                            #femaleContent></textarea>
                        <mat-error *ngIf="femaleContentFormControl.hasError('required')">
                            {{'Female content is required' | translate}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>{{'Other Content' | translate}}</mat-label>
                        <span class="insert-character">
                            <mat-icon (click)="insertSpecialString(otherContent, otherContentFormControl)"
                                [title]="'Click here to insert a special string that will be replaced by the character name' | translate">
                                perm_identity</mat-icon>
                        </span>
                        <textarea matInput placeholder="" rows="5" [formControl]="otherContentFormControl"
                            #otherContent></textarea>
                        <mat-error *ngIf="otherContentFormControl.hasError('required')">
                            {{'Other content is required' | translate}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit text-right">
            <div class="kt-form__actions kt-form__actions--solid">
                <br />
                <button mat-raised-button (click)="closeDialog()">{{'Cancel' |
                    translate}}</button>&nbsp;
                <button mat-raised-button color="primary" *ngIf="!openingActId" (click)="saveOpeningAct()">{{'Save' |
                    translate}}</button>
                <button mat-raised-button color="primary" *ngIf="openingActId" (click)="updateOpeningAct()">{{'Update' |
                    translate}}</button>
            </div>
        </div>
    </div>
</div>