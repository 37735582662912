import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatChipInputEvent } from "@angular/material/chips";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { AuthenticationService } from "src/app/core/services/auth.service";
import { LayoutUtilsService } from "src/app/core/services/layout-utils.service";
import { RequestService } from "src/app/core/services/request.service";
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ConfirmDialogComponent } from "../../confirm-dialog/confirm-dialog.component";

export interface Tag {
    name: string;
}

@Component({
    selector: 'app-tags',
    templateUrl: './tags.component.html',
    styleUrls: ['./tags.component.scss']
})

export class TagsComponent implements OnInit, OnDestroy {

    private subscriptions: any[] = <any>[];
    private flowId: string = undefined;
    private flow: any = undefined;

    public loading: boolean = false;
    public title: string = 'Tags';
    public tags: Tag[] = [];

    readonly separatorKeysCodes = [ENTER, COMMA] as const;

    constructor(private requestService: RequestService, private layoutUtilsService: LayoutUtilsService, private translate: TranslateService, private dialogRef: MatDialogRef<TagsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialog, private authService: AuthenticationService) {
        this.flowId = data.flowId;

        if (data.tags) {
            this.title = Object.keys(data.tags)[0];
            this.tags = <Tag[]>Object.values(data.tags)[0];
            this.flow = data.flow;
        }
    }

    ngOnInit() {

    }

    ngOnDestroy() {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    closeDialog(data?: any) {
        this.dialogRef.close(data);
    }

    add(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();

        if (value) {
            if (this.tags.find(i => i.name.toLowerCase() === value.toLowerCase()))
                this.layoutUtilsService.showNotification(this.translate.instant('This tag already exists'), this.translate.instant('Dismiss'));
            else
                this.tags.push({ name: value });
        }

        // Clear the input value
        event.chipInput!.clear();
    }

    remove(tag: Tag): void {
        let findTagInCondition = this.flow.nodes.find(i => i.type === 'condition' && i.metadata.fields.find(j => j.fieldTags.includes(tag.name)));
        let findTagInState = this.flow.nodes.find(i => i.type === 'state' && i.metadata.tags?.includes(tag.name));
        if (findTagInCondition || findTagInState) {
            const dialog = this.dialog.open(ConfirmDialogComponent, {
                disableClose: true,
                autoFocus: true,
                width: 'fit-content',
                data: {
                    title: '',
                    message: this.translate.instant('This tag is being used inside the flow. Are you sure you want to delete it?')
                }
            });
            dialog.afterClosed().subscribe(value => {
                if (value) {
                    this.removeTag(tag);
                }
            });
        }
        else {
            this.removeTag(tag);
        }
    }

    private removeTag(tag: Tag) {
        const index = this.tags.indexOf(tag);

        if (index >= 0) {
            this.tags.splice(index, 1);
        }
    }

    saveTags() {
        this.loading = true;
        let obj = { tags: {} };
        obj.tags[this.title] = this.tags;

        this.requestService.saveRecord(obj, 'flow/' + this.flowId, (data, error) => {
            if (data)
                this.layoutUtilsService.showNotification(this.translate.instant('Updated Successfully'), this.translate.instant('Dismiss'));
            if (error)
                this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
            this.closeDialog(true);
            this.loading = false;
        });
    }
}