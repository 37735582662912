<div class="kt-portlet">
    <div class="kt-portlet__head kt-portlet__head__custom">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">{{data.title}}</h3>
            <span class="kt-portlet__head-icon kt-hide">
                <i class="la la-gear"></i>
            </span>
        </div>
    </div>
    <div class="kt-form">
        <div class="kt-portlet__body container">
            <mat-spinner [diameter]="50" class="loader" *ngIf="loading"></mat-spinner>
            <div fxLayout="row" *ngIf="!loading" fxLayoutGap="0.5vw">
                <div fxFlex fxLayout="column" fxGrow="0" fxLayoutAlign="start center">
                    <figure class="card-profile-image" fxFlex fxGrow="0">
                        <img [src]="pictureLink" style="width: 100px; height:100px;"
                            class="circle z-depth-2 responsive-img activator">
                    </figure>
                </div>
                <div fxLayout="column" fxLayoutGap="0.5vw" style="width: 100%;">
                    <mat-form-field appearance="fill" fxFlex>
                        <mat-label>{{'First Name' | translate}}</mat-label>
                        <input matInput placeholder="" required [formControl]="firstNameFormControl">
                        <mat-error *ngIf="firstNameFormControl.hasError('required')">
                            {{'First name is required' | translate}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill" fxFlex>
                        <mat-label>{{'Last Name' | translate}}</mat-label>
                        <input matInput placeholder="" required [formControl]="lastNameFormControl">
                        <mat-error *ngIf="lastNameFormControl.hasError('required')">
                            {{'Last name is required' | translate}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill" fxFlex>
                        <mat-label>{{'Email' | translate}}</mat-label>
                        <input matInput placeholder="" type="email" required [formControl]="emailFormControl">
                        <mat-error *ngIf="emailFormControl.hasError('required')">
                            {{'Email is required' | translate}}
                        </mat-error>
                        <mat-error *ngIf="emailFormControl.hasError('email')">
                            {{'Please enter a valid email address' | translate}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit text-right">
            <div class="kt-form__actions kt-form__actions--solid">
                <br />
                <button mat-raised-button (click)="closeDialog()">{{'Cancel' |
                    translate}}</button>&nbsp;
                <button mat-raised-button color="primary" *ngIf="!clientId" (click)="saveClient()">{{'Save' |
                    translate}}</button>
                <button mat-raised-button color="primary" *ngIf="clientId" (click)="updateClient()">{{'Update' |
                    translate}}</button>
            </div>
        </div>
    </div>
</div>