<div class="navbar-container" [class.example-is-mobile]="mobileQuery.matches">

  <!-- Top navbar -->
  <mat-toolbar class="navbar">

    <button mat-icon-button (click)="snav.toggle()">
      <mat-icon>menu</mat-icon>
    </button>

    <a class="navbar-brand" [routerLink]="['/']" matTooltip="Home">
      <img src="../../../assets/images/logo.png">
      <span>
        {{'Flow Engine' | translate}}
      </span>
    </a>

    <span class="navbar-spacer"></span>

    <mat-form-field style="font-size: 10pt;">
      <mat-label>{{'My Dashboard' | translate}}</mat-label>
      <mat-select [formControl]="selectedClient">
        <mat-select-trigger>
          {{selectedClientName}}
        </mat-select-trigger>
        <mat-option>{{'My Dashboard' | translate}}</mat-option>
        <mat-option *ngFor="let client of clients" [value]="client._id">
          {{client.name}}
          <div class="sub-label">{{client.email}}</div>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <!-- <select [formControl]="selectedClient" *ngIf="isSuperAdmin && clients.length"
      (change)="switchDashboard($event.target.value)">
      <option value="">{{'My Dashboard' | translate}}</option>
      <option *ngFor="let client of clients" [value]="client._id">{{client.name}}</option>
    </select> -->

    <button mat-button [matMenuTriggerFor]="userMenu">
      <mat-icon>person</mat-icon>
      <span fxShow fxHide.xs>
        {{userName}}
      </span>
    </button>
    <mat-menu #userMenu="matMenu" xPosition="before" yPosition="above" [overlapTrigger]="false">

      <!-- <a mat-menu-item [routerLink]="['/account/profile']">
        <mat-icon>person</mat-icon>
        <span>Account</span>
      </a> -->

      <a mat-menu-item (click)="onLoggedout()">
        <mat-icon>exit_to_app</mat-icon>
        <span>{{'Log out' | translate}}</span>
      </a>

    </mat-menu>

  </mat-toolbar>

  <mat-sidenav-container class="navbar-sidenav-container">
    <!-- Side nav -->
    <mat-sidenav #snav [opened]="!mobileQuery.matches" [mode]="mobileQuery.matches ? 'over' : 'side'"
      [fixedInViewport]="mobileQuery.matches" fixedTopGap="56" class="sidenav">
      <mat-nav-list>
        <a mat-list-item [routerLink]="['/']" [class.active]="currentRoute === '/'">
          <mat-icon mat-list-icon>
            list
          </mat-icon>
          <p mat-line> {{'Flow List' | translate}} </p>
        </a>
        <a mat-list-item [routerLink]="['/openingActs/list']" [class.active]="currentRoute === '/openingActs/list'">
          <mat-icon mat-list-icon>
            flag
          </mat-icon>
          <p mat-line> {{'Opening Acts List' | translate}} </p>
        </a>
        <a mat-list-item [routerLink]="['/characters/list']" [class.active]="currentRoute === '/characters/list'">
          <mat-icon mat-list-icon>
            face
          </mat-icon>
          <p mat-line> {{'Character List' | translate}} </p>
        </a>
        <mat-expansion-panel [expanded]="true" *ngIf="isSuperAdmin">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{'Admin' | translate}}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <a mat-list-item [routerLink]="['/clients/list']" [class.active]="currentRoute === '/clients/list'">
            <mat-icon mat-list-icon>
              people
            </mat-icon>
            <p mat-line> {{'Clients' | translate}} </p>
          </a>
          <a mat-list-item [routerLink]="['/users/list']" [class.active]="currentRoute === '/users/list'">
            <mat-icon mat-list-icon>
              people
            </mat-icon>
            <p mat-line> {{'Users' | translate}} </p>
          </a>
        </mat-expansion-panel>
        <a mat-list-item [routerLink]="['/users/list']" *ngIf="!isSuperAdmin"
          [class.active]="currentRoute === '/users/list'">
          <mat-icon mat-list-icon>
            people
          </mat-icon>
          <p mat-line> {{'Users' | translate}} </p>
        </a>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content class="sidenav-content">
      <app-loading-screen></app-loading-screen>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>