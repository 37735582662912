import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { CustomMaterialModule } from './custom-material/custom-material.module';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';
import { NgxPermissionsModule } from 'ngx-permissions';
import { LoaderService } from './core/services/loader.service';
import { StoreService } from './core/services/store.service';
import { RequestService } from './core/services/request.service';
import { LayoutUtilsService } from './core/services/layout-utils.service';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { GlobalErrorHandler, RollbarService, rollbarFactory } from './core/services/globar-error.handler';
import { DiagramService } from './core/services/diagram.service';
import { CharactersListComopnent } from './features/dashboard/characters-list/characters-list.component';
import { ClientsListComopnent } from './features/dashboard/clients/clients-list.component';
import { DiagramComponent } from './features/dashboard/diagram/diagram.component';
import { FlowListComponent } from './features/dashboard/flow-list/flow-list.component';
import { OpeningActsListComponent } from './features/dashboard/opening-acts-list/opening-acts-list.component';

export const createTranslateLoader = (http: HttpClient) => {
  return new TranslateHttpLoader(http, './assets/', '.json');
};

@NgModule({
  declarations: [
    AppComponent, FlowListComponent, DiagramComponent, OpeningActsListComponent, CharactersListComopnent, ClientsListComopnent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    CustomMaterialModule.forRoot(),
    AppRoutingModule,
    NgxPermissionsModule.forRoot(),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    LoaderService,
    StoreService,
    RequestService,
    DiagramService,
    LayoutUtilsService,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: RollbarService, useFactory: rollbarFactory },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
