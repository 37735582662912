// Angular
import { Component, Inject, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, interval, fromEvent } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'kt-alert-action-entity-dialog',
	templateUrl: './alert-action-entity-dialog.component.html',
	styleUrls: ['./alert-action-entity-dialog.component.scss']
})
export class AlertActionEntityDialogComponent implements OnInit {
	public subscriptions: any[] = <any>[];
	/**
	 * Component constructor
	 *
	 * @param dialogRef: MatDialogRef<AlertEntityDialogComponent>
	 * @param data: any
	 */
	constructor(
		public dialogRef: MatDialogRef<AlertActionEntityDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		// console.log('AlertActionEntityDialogComponent', data);
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		if (this.data.alertSetting.hasOwnProperty('timerEvent')) {
			this.subscriptions.push(interval(1000 * this.data.alertSetting.timerEvent).subscribe(() => {
				this.onYesClick('confirmText', this.data.alertSetting.confirmText);
			}));
		}
	}
	ngOnDestroy() {
		this.subscriptions.forEach((s) => s.unsubscribe());
	}

	/**
	 * Close dialog with false result
	 */
	onNoClick(): void {
		this.dialogRef.close();
	}
	onYesClick(action: any, value: any): void {
		this.dialogRef.close({ action, value });
	}
}
