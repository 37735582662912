<div class="col-xl-12 cropPopup">
	<div mat-dialog-title class="dialog-title">
		<div class="mb-20" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
			<div fxFlex class="filterName">
				{{data.title}}
			</div>
		</div>
	</div>
	<div mat-dialog-content class="dialog-content">
		<div class="ImageTabContainer" *ngIf="!imageUrlPath">
			<img src="{{pictureLink}}">
		</div>
		<div *ngIf="imageUrlPath">
			<image-cropper style="height:300px" [imageBase64]="imageUrlPath"
				[maintainAspectRatio]="!(selectedRatio === 0)" [aspectRatio]="selectedRatio" [format]="imageExtension"
				[autoCrop]="false" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
				(cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"></image-cropper>
		</div>

	</div>
	<div mat-dialog-actions class="dialog-actions">
		<div style="width: 100%;margin-top:10px;">
			<button mat-raised-button color="danger" (click)="closeModal(undefined)">{{'Close' | translate}}</button>
			<input #imageInput [hidden]="true" type="file" name="upfileLogo" accept="{{allowedAcceptExtensions}}"
				multiple="false" id="image_selector" (change)="onBrowseFiles($event.target)">
			<button mat-raised-button (click)="imageInput.click()" [disabled]="loading">{{'Select' |
				translate}}</button>
			<button mat-raised-button (click)="cropIt()" *ngIf="imageUrlPath && imageSelected"
				[disabled]="loading">{{'Confirm Crop and Upload' | translate}}</button>
			<button mat-raised-button (click)="clearIt($event)" *ngIf="imageUrlPath && imageSelected"
				[disabled]="loading">{{'Clear' | translate}}</button>
		</div>
	</div>
	<br />
</div>