import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ContentType } from './enums/contentType';
import { Dictionary } from './interface/dictionary.interface';
import { urlSafeBase64Encoding } from './helpers/base64';
import { StoreService } from './store.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { guid } from '../helpers/guid';

@Injectable()
export class RequestService {
  private authURL = environment.serverUrl;
  protected authCASURL = environment.casUrl;
  protected productId = environment.productId;
  private loading: boolean = false;
  private token: any = '';
  private userType: string = 'default';
  public orgId = environment.orgId;
  public appId = undefined;
  public orgName: string = '';
  public locId = undefined;
  public lang = 'en';
  public newAppVersion: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public version: string = '0.0';
  public environmentserverHostUrl = environment.serverHostUrl;
  public serverHostUrl = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '');
  public appStatusSubject: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  public authenticatedUser: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public pageOrganization: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  private cachedObj: any = {};
  public selectedClientIdForDashboard: string = '';
  public reloadFlowList: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public currentUserSubject: BehaviorSubject<any | undefined> = new BehaviorSubject<any | undefined>(undefined);
  public _currentUser: any | undefined = undefined;
  set currentUser(currentUser: any | undefined) {
    if (currentUser) {
      this._currentUser = currentUser;
      let userObject: any = currentUser;
      this.userType = currentUser.type || 'default';
      this.store.init('default_' + userObject._id);
      this.setToken(userObject.token);
      this.currentUserSubject.next(userObject);
      // this.store.set('te', 'temp');
      this.permissionsService.flushPermissions();
      for (let res of userObject.resources) {
        this.permissionsService.addPermission(res._id);
      }
    } else {
      this._currentUser = undefined;
      // this.orgId = undefined;
      this.currentUserSubject.next(undefined);
      this.token = '';
      this.userType = 'default';
      this.store.init('default');
      this.permissionsService.flushPermissions();
    }
  }
  get currentUser(): any | undefined {
    return this._currentUser;
  }
  public updateCurrentUser(newData: any) {
    let objectUser = Object.assign(this._currentUser, newData);
    this.currentUser = objectUser;
  }
  public updateUserData(key: any, value: any) {
    let userObject: any = this.currentUserSubject.getValue();
    // let currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    userObject[key] = value;
    localStorage.setItem('currentUser', JSON.stringify(userObject));
    this.currentUserSubject.next(userObject);
  }
  constructor(public store: StoreService, private router: Router, private http: HttpClient,
    private permissionsService: NgxPermissionsService
  ) {
    this.store.init('Anonomous');
  }
  public loggedIn() {
    if (this.currentUser) {
      return true;
    } else {
      return false;
    }
  }
  public getUserType() {
    return this.userType;
  }
  public getUserId() {
    if (this.currentUser && this.currentUser.hasOwnProperty('_id')) {
      return this.currentUser['_id'];
    } else {
      return '';
    }
  }
  public setToken(token: any) {
    this.token = token;
  }
  public getToken() {
    return this.token;
  }
  public redirectTo(uri: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([uri]));
  }
  public getItemFromListContains(lst: any[], val: string, idKey: string = '_id'): any {
    if (lst) {
      for (let itm of lst) {
        if (itm.hasOwnProperty(idKey) && itm[idKey] === val) {
          return itm;
        }
      }
    }
    return undefined;
  }
  public updatePermissions(resources: any) {
    this.permissionsService.flushPermissions();
    this.permissionsService.addPermission(resources._id);
  }
  public logout(redirect = true, showDialog = false) {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('o');
    localStorage.removeItem('org');
    localStorage.removeItem('a');
    localStorage.removeItem('l');
    sessionStorage.removeItem('live');
    // this.logOutApi();
    // sessionStorage.clear()
    this.appStatusSubject.next(undefined);
    this.authenticatedUser.next(false);
    this.currentUser = undefined;
    if (redirect) {
      if (showDialog) {
        this.router.navigate(['/auth/login', 'showDialog']);
      } else {
        this.router.navigate(['/auth/login']);
      }
    }
  }

  public requestLogin(username: string, password: string, callback: (dataResponse: any | undefined,
    requestError: any | undefined, returnIt: boolean) => void, lang?: string) {
    let encodedPassword = urlSafeBase64Encoding(password); // make it from backend
    let uuid = guid();
    // let objData = {
    //   "productId": this.productId,
    //   "userName": username.trim(),
    //   "password": encodedPassword,
    //   "organizationId": this.orgId,
    //   "uuid": uuid
    // }
    let urlStr = this.authCASURL + 'user/login?email=' + username + '&password=' + encodedPassword + '&organizationId=' + this.orgId + '&uuid=' + uuid;
    // urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        //console.log(error);
        if (jsonObj && jsonObj.hasOwnProperty('return')) {
          callback(undefined, error, jsonObj.return);
        } else {
          callback(undefined, error, false);
        }
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          let userObject = jsonObj.results;
          if (jsonObj.hasOwnProperty('token')) {
            this.setToken(jsonObj.token);
            userObject['token'] = jsonObj.token;
          }
          callback(userObject, undefined, false);
        } else {
          if (jsonObj.hasOwnProperty('return')) {
            callback(undefined, jsonObj, jsonObj.return);
          } else {
            callback(undefined, jsonObj, false);
          }
        }
      } else {
        callback(undefined, 'Data error from server ', false);
      }
    });
  }

  // public requestLogin(username: string, password: string, callback: (dataResponse: any | undefined,
  //   requestError: any | undefined, returnIt: boolean) => void, lang?: string) {
  //   let encodedPassword = urlSafeBase64Encoding(password); // make it from backend
  //   let urlStr = this.authURL + 'user/login?email=' + encodeURIComponent(username.trim()) + '&password=' + encodedPassword + '&vertical=' + this.orgType + '&organizationId=' + environment.orgId;
  //   this.jsonGetRequest(urlStr, (jsonObj, error) => {
  //     if (error !== undefined) {
  //       //console.log(error);
  //       if (jsonObj && jsonObj.hasOwnProperty('return')) {
  //         callback(undefined, error, jsonObj.return);
  //       } else {
  //         callback(undefined, error, false);
  //       }
  //       return;
  //     }
  //     if (jsonObj) {
  //       if (jsonObj.status) {
  //         let userObject = jsonObj.results;
  //         if (jsonObj.hasOwnProperty('token')) {
  //           this.setToken(jsonObj.token);
  //           userObject['token'] = jsonObj.token;
  //         }
  //         callback(userObject, undefined, false);
  //       } else {
  //         if (jsonObj.hasOwnProperty('return')) {
  //           callback(undefined, jsonObj, jsonObj.return);
  //         } else {
  //           callback(undefined, jsonObj, false);
  //         }
  //       }
  //     } else {
  //       callback(undefined, 'Data error from server ', false);
  //     }
  //   });
  // }

  private toBase64(stringToSign: string) {
    let base64 = btoa(stringToSign);
    return base64;
  }

  private jsonGetRequest(urlString: string, callback: (json?: any, error?: any) => void, params?: Dictionary) {
    if (urlString) {
      let urlComps = urlString;
      if (params) {
        for (let urlItem of Object.keys(params)) {
          urlComps += '&' + urlItem + '=' + params[urlItem];
        }
      }
      this.jsonRequest(urlComps, callback, 'GET');
    } else {
      return;
    }
  }

  private jsonRequest(urlString: string,
    callback: (json: any, error: any) => void,
    method: string = 'POST',
    postBody: any = undefined,
    contentType: string = ContentType.JSON,
    timeout: number = 10.0,
    retry: boolean = false,
    retryFactor: number = 1.5,
    maxTimeout: number = 60.0, includeToken: boolean = false) {
    if (urlString) {
      let url: string = urlString || '';

      let headers: any = {
        'Content-Type': contentType,
        'Accept': 'application/json'
      };
      if (this.token && (urlString.startsWith(this.authURL) || includeToken)) {
        headers['Authorization'] = this.token;
      }
      headers['productid'] = environment.productId;

      let httpOptions: any = {
        responseType: 'json',
        headers: new HttpHeaders(headers),
        method: method
      }
      if (this.orgId && this.orgId !== '') {
        headers['organizationid'] = this.orgId;
        headers['integratedid'] = this.orgId;
      }
      let bodyString = postBody;
      if (method === 'POST') {
        bodyString = JSON.stringify(postBody);
        httpOptions['body'] = bodyString;
      }
      this.http.request(method, url, httpOptions)
        // .pipe(map(
        //     (res: any) => {
        //       // below might need to be changed
        //       if (res.status >= 404) {
        //         window.location.reload();
        //       } else if (res.status >= 400) {
        //         callback(undefined, 'server');
        //         return;
        //       }
        //       return res;
        //     }
        //   ))
        .subscribe(
          (data) => {
            callback(data, undefined);
            // console.log(url, data);
          },
          (err) => {
            if (err) {
              if (err.status >= 404) {
                // window.location.reload();
                callback(undefined, 'Refresh page');
              } else if (err.status >= 400) {
                try {
                  if (err.error.type === 'login')
                    this.appStatusSubject.next('login');
                  else
                    callback(undefined, 'server');
                  // let jsonErr = err.json();
                  // if (jsonErr.hasOwnProperty('type') && jsonErr.type === 'login') {
                  //   this.appStatusSubject.next(jsonErr.type);
                  //   // this.logout();
                  // } else {
                  //   callback(undefined, 'server');
                  // }
                } catch (e) {
                  callback(undefined, 'server');
                }
              } else {
                callback(undefined, err);
              }
            }
          });

    } else {
      // this.logger.log('Failed to create URL');
      //console.log('Failed to create URL');
    }
  }

  public getDataFromListContains(lst: any[], val: string[], idKey: string = '_id'): any[] {
    let dataList = [];
    for (let itm of lst) {
      if (itm.hasOwnProperty(idKey) && val.includes(itm[idKey])) {
        dataList.push(itm);
      }
    }
    return dataList;
  }

  public getDataFromList(lst: any[], idKey: string = '_id'): string[] {
    let dataList = [];
    for (let itm of lst) {
      if (itm.hasOwnProperty(idKey)) {
        dataList.push(itm[idKey]);
      }
    }
    return dataList;
  }

  public getUserRoleByUserData(user: any): string {
    if (user) {
      if (user['resources'].find((role: any) => role['_id'] == environment.customKeys.roleView))
        return 'view';
      else if (user['resources'].find((role: any) => role['_id'] == environment.customKeys.roleAdmin))
        return 'admin';
    }
    return 'anonymous';
  }

  public getMe(callback: (dataResponse: any | undefined,
    requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'user/me/' + this.orgId;
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          let userObject = jsonObj.results;
          callback(userObject, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, 'Data error from server ');
      }
    });
  }

  public getSingleCachData(type: string, id: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, cached: boolean = false, lang?: string) {
    if (cached) {
      if (this.cachedObj.hasOwnProperty(type + '/' + id)) {
        callback(this.cachedObj[type + '/' + id], undefined);
        return;
      }
    }
    let urlStr = this.authURL + type + '/' + id;
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          if (cached) {
            this.cachedObj[type] = jsonObj.results;
          }
          callback(jsonObj.results, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }

  public getSingleData(type: string, id: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + type + '/' + id;
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          // if (type === 'session') {
          //   jsonObj.results.users = this.featuresPermissionService.validateSessionUsers(jsonObj.results.users);
          // }
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }

  public editUserPassword(oldpassword: any, newpassword: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'user/changepassword';
    let encodedOldPassword = urlSafeBase64Encoding(oldpassword);
    let encodedNewPassword = urlSafeBase64Encoding(newpassword);
    let user = { oldpassword: encodedOldPassword, newpassword: encodedNewPassword };

    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', user);
  }

  public confirmUserPassword(email: string, confirmationCode: any, newpassword: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authCASURL + 'user/confirm/forgotpassword';
    let encodedNewPassword = urlSafeBase64Encoding(newpassword);
    let user = { email: email.trim(), confirmationCode: confirmationCode, newPassword: encodedNewPassword };

    // urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', user, ContentType.JSON, 10.0, false, 1.5, 60.0, true);
  }

  public saveRecord(obj: any, type: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    this.jsonRequest(this.authURL + type, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', obj);
  }

  public updateRecord(obj: any, type: string, id: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    this.jsonRequest(this.authURL + type + '/' + id, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', obj);
  }

  public getDataListByOrgByGet(type: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + type + '/list/' + this.orgId;
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }

  public deleteData(type: string, id: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void) {
    let urlStr = this.authURL + type + '/' + id + '/delete';
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST');
  }

  public getRecord(id: string, type: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void) {
    let urlStr = this.authURL + type + '/' + id;
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }

  private buildSearchRequestSToAPI(conf: any, token: string = '', addCustomData: boolean = true): {} {
    let searchRequestGeneric: any = {
    };
    if (conf.perpage) {
      searchRequestGeneric['count'] = conf.perpage || 10;
    }
    if (searchRequestGeneric.count === -1) {
      delete searchRequestGeneric.count;
    }
    if (conf.orderBy && conf.orderDir) {
      searchRequestGeneric['order'] = [];
      searchRequestGeneric['order'].push({ field: conf.orderBy, order: conf.orderDir });
    }
    if (conf.order) {
      searchRequestGeneric['order'] = conf.order;
    }
    let fieldList: string[] = [];
    if (conf.hasOwnProperty('fieldKeys')) {
      fieldList = conf['fieldKeys'];
    }
    if (fieldList.length > 0) {
      searchRequestGeneric['fields'] = fieldList;
    }
    if (conf.hasOwnProperty('term') && conf['term'] !== undefined) {
      searchRequestGeneric['term'] = conf['term'] || '';
    }
    if (conf.hasOwnProperty('termfields') && conf['termfields'] !== undefined) {
      searchRequestGeneric['termfields'] = conf['termfields'] || '';
    }
    let filterList: any = {};
    if (conf.customData && addCustomData) {
      if (Object.keys(conf.customData).length > 0) {
        for (let field of Object.keys(conf.customData)) {
          if (field)
            filterList[field] = { op: 'eq', value: conf.customData[field] };
        }
      }
    }
    if (conf.filterFieldKey) {
      for (let field of conf.filterFieldKey) {
        if (field) {
          filterList[field.field] = { op: field.op, value: field.search };
          if (field.type && field.type === 'number') {
            filterList[field.field].value = Number(filterList[field.field].value);
          }
        }
      }
    }
    if (Object.keys(filterList).length > 0) {
      searchRequestGeneric['filter'] = filterList;
    }
    if (conf.hasOwnProperty('filter')) {
      searchRequestGeneric['filter'] = conf.filter;
    }
    if (conf.hasOwnProperty('page')) {
      searchRequestGeneric['page'] = conf.page;
    }
    if (token !== '') {
      searchRequestGeneric['paginationToken'] = token;
    }
    if (conf.hasOwnProperty('include') && conf['include'] !== undefined) {
      searchRequestGeneric['include'] = conf['include'] || [];
    }
    if (conf.hasOwnProperty('exclude') && conf['exclude'] !== undefined) {
      searchRequestGeneric['exclude'] = conf['exclude'] || [];
    }
    if (conf.hasOwnProperty('organizationId') && conf['organizationId'] !== undefined) {
      searchRequestGeneric['organizationId'] = conf['organizationId'] || '';
    }
    return searchRequestGeneric;
  }

  public getDataListSummary(type: string, conf: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, cached: boolean = false, lang?: string) {
    let cleanConf = this.buildSearchRequestSToAPI(conf, '');
    let urlStr = this.authURL + type + '/search/summary';
    return this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          if (cached) {
            this.cachedObj[type] = jsonObj;
          }
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', cleanConf);
  }

  public onUploadUserImage(browsed_file: any, id: string): Observable<{}> {

    let headers: any = {
      'Authorization': this.token,
      'Accept': 'application/json',
      'productid': environment.productId
    };

    if (this.orgId && this.orgId !== '') {
      headers['organizationid'] = this.orgId;
      headers['integratedid'] = this.orgId;
    }
    let httpOptions = {
      headers: new HttpHeaders(headers)
    }
    let url = this.authURL + 'user/image/upload/' + id;
    let formData = new FormData();
    formData.append('upfile', browsed_file.originalFile);
    formData.append('name', browsed_file.text);
    return this.http.post(url,
      formData, httpOptions).pipe(map((response: any) => {
        let jsonObj = response;
        if (jsonObj) {
          if (jsonObj.status) {
            return jsonObj;
          } else {
            return { status: false, message: 'Upload Unsuccessful' };
          }
        } else {
          return { status: false, message: 'Upload Unsuccessful' };
        }
      }));
  }

  public deleteProfileImage(type: string, id: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + type + '/' + id + '/deleteprofile';
    let objData = {
      // folder: "string",
      url: id
    };
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', objData);
  }

  public uploadFile(id: any, name: string, file: any, type: string, urlPath: string, nodeId: string): Observable<{}> {
    let headers: any = {
      'Authorization': this.token,
      'Accept': 'application/json',
      'productid': environment.productId
    };

    if (this.orgId && this.orgId !== '') {
      headers['organizationid'] = this.orgId;
      headers['integratedid'] = this.orgId;
    }

    let httpOptions = {
      headers: new HttpHeaders(headers)
    }
    let url = this.authURL + urlPath + id;
    let formData = new FormData();
    formData.append('upfile', file);
    formData.append('name', name);
    formData.append('type', type);

    if (nodeId) {
      formData.append('nodeId', nodeId);
    }
    return this.http.post(url,
      formData, httpOptions).pipe(map((response: any) => {
        let jsonObj = response;
        if (jsonObj) {
          if (jsonObj.status) {
            return jsonObj;
          } else {
            return { status: false, message: 'Upload Unsuccessful' };
          }
        } else {
          return { status: false, message: 'Upload Unsuccessful' };
        }
      }));
  }

  public uploadCharacterImage(id: any, name: string, file: any): Observable<{}> {
    let headers: any = {
      'Authorization': this.token,
      'Accept': 'application/json',
      'productid': environment.productId
    };

    if (this.orgId && this.orgId !== '') {
      headers['organizationid'] = this.orgId;
      headers['integratedid'] = this.orgId;
    }

    let httpOptions = {
      headers: new HttpHeaders(headers)
    }
    let url = this.authURL + 'character/image/upload/' + id;
    let formData = new FormData();
    formData.append('upfile', file);
    formData.append('name', name);
    return this.http.post(url,
      formData, httpOptions).pipe(map((response: any) => {
        let jsonObj = response;
        if (jsonObj) {
          if (jsonObj.status) {
            return jsonObj;
          } else {
            return { status: false, message: 'Upload Unsuccessful' };
          }
        } else {
          return { status: false, message: 'Upload Unsuccessful' };
        }
      }));
  }

  public deleteFile(url: string, id: any, subId: string, type: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + url + '/' + id + '/' + subId + '/' + type + '/delete';
    let objData = {
      id: id,
      nodeId: subId,
      type: type
    };
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', objData);
  }

  public logOutApi() {
    if (!this.loading && this.token !== '') {
      this.loading = true;
      // let data = {};
      let urlStr = this.authCASURL + 'user/logout/' + this.orgId;
      this.jsonGetRequest(urlStr, (jsonObj, error) => {
        if (error !== undefined) {
          //do nothing
          this.logout(true, false);
        }
        if (jsonObj) {
          this.logout(true, false);
        }
        this.loading = false;
      });
    }
  }

  public forgotPassword(email: string, callback: (dataResponse: any | undefined,
    requestError: any | undefined, returnIt: boolean) => void, lang?: string) {
    let urlStr = this.authCASURL + 'user/forgotpassword?email=' + encodeURIComponent(email.trim()) + '&organizationId=' + this.orgId;
    // urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        if (error.hasOwnProperty('return')) {
          callback(undefined, error, jsonObj.return);
        } else {
          callback(undefined, error, false);
        }
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined, false);
        } else {
          if (jsonObj.hasOwnProperty('return')) {
            callback(undefined, jsonObj.message, jsonObj.return);
          } else {
            callback(undefined, jsonObj.message, false);
          }
        }
      } else {
        callback(undefined, 'Data error from server ', false);
      }
    });
  }

  public getDataList(type: string, conf: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, source: string = '', sourceTarget: string = '', lang?: string) {
    let cleanConf = this.buildSearchRequestSToAPI(conf, '');
    let urlStr = this.authURL + source + type + '/search' + sourceTarget;
    return this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          // console.log('jsonObj', jsonObj);
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', cleanConf);
  }

  public getMetaData(type: string, feilds: any[], callback: (dataResponse: any | undefined, requestError: any | undefined) => void, orgId?: string, lang?: string) {
    let urlStr = this.authURL + type + '/metadata';
    let ic = '?';
    if (feilds) {
      urlStr = urlStr + ic + 'fields=' + feilds;
      ic = '&';
    }
    if (orgId) {
      urlStr = urlStr + ic + 'organizationId=' + orgId;
      ic = '&';
    }
    if (lang) {
      urlStr = urlStr + ic + 'language=' + lang;
    }
    // urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }

  public isUserRoleAdmin(): boolean {
    let user = this.currentUser;
    if (user) {
      if (user['resources'].find(role => role['_id'] === environment.customKeys.roleAdmin && role['organizationId'] === this.orgId))
        return true;
    }
    return false;
  }
  public isUserRoleSuperAdmin(): boolean {
    let user = this.currentUser;
    if (user && user.isSuperAdmin) {
      if (user['resources'].find(role => role['_id'] === environment.customKeys.roleAdmin && role['organizationId'] === this.orgId))
        return true;
    }
    return false;
  }

  public onUploadFilesByPathNew(path: string, browsed_file: any, type: string = undefined, isEncoded: string = undefined, id: string = undefined, folder: string = undefined, addName: boolean = true): Observable<HttpEvent<any>> {
    let headers = {
      'Authorization': this.token,
      'Accept': 'application/json'
    };
    headers['productid'] = environment.productId;
    if (this.orgId && this.orgId !== '') {
      headers['organizationid'] = this.orgId;
      headers['integratedid'] = this.orgId;
    }
    let httpOptions: any = {
      headers: new HttpHeaders(headers),
      reportProgress: true,
      responseType: 'json'
    }
    let url = this.authURL + path;
    const formData: FormData = new FormData();
    formData.append('upfile', browsed_file.originalFile);
    if (addName) {
      formData.append('name', browsed_file.text);
    }
    if (folder !== undefined) {
      formData.append('folder', folder);
    }
    if (type !== undefined) {
      formData.append('type', type);
    }
    if (id !== undefined) {
      formData.append('id', id);
    }
    if (isEncoded !== undefined) {
      formData.append('isEncoded', isEncoded);
    }

    const req = new HttpRequest('POST', url, formData, httpOptions);
    return this.http.request(req);
  }
}
